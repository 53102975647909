<template>
  <div class="wrapper overflow-y-auto" :style="{'--bg':backgroundImage}">
    <div class="homePageContainer relative h-full w-full overflow-y-auto">
      <div>
        <p class="fs-32 fw-400 text-white mt-10 description">
          Your account is verified now. You can login to your account now.
        </p>
<!--        <p class="fs-48 fw-400 text-white mt-10 description" v-if="!verified">-->
<!--          Link is not verified. Please check the link again.-->
<!--        </p>-->
        <div class="loginLink relative fs-36 fw-500 text-white mt-10 capitalize">
<!--          <router-link v-if="!verified" :to="{ name:'register' }">Sign Up Now</router-link>-->
          <router-link :to="{ name:'login' }">Sign In Now</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {get} from "lodash";

export default {
  name: "VerifyAccount",
  created(){
    document.title = 'Verify Account'
  },
  data() {
    return {
      backgroundImage: `url(${require('@/assets/images/homepageBg.webp')})`,
    }
  },
  async beforeRouteEnter(to, from, next) {
    let params = get(to, 'query');
    if(params && params.link) {
      let verify = await store.dispatch('verifyUser', params.link);
      if(verify) {
        next();
      } else {
        if(confirm("Link is not valid")) {
          next({
            name: 'register'
          });
        }
      }
    } else {
      next({
        name: 'register'
      });
    }
  }
}
</script>

<style scoped>
.wrapper{
  height: 100vh;
  background-image: linear-gradient(rgb(65 161 212 / 95%), rgb(43 128 173 / 89%)), var(--bg);;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.homePageContainer{
  padding: 0 20%;
  padding-top: 360px;
}
.homePageContainer .description{
  line-height: 72px;
}

.loginLink{
  width: fit-content;
}
.loginLink::before{
  content: '';
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 3px solid #fff;
  width: 235px;
}

@media all and (max-width: 1500px){
  .homePageContainer{
    padding-left: 16%;
    padding-right: 16%;
  }
  .homePageContainer .description{
    font-size: 32px;
    line-height: 48px;
  }
  .loginLink{
    font-size: 30px;
  }
}
@media all and (max-width: 1000px){
  .homePageContainer{
    display: grid;
    place-content: center;
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 0;
  }
  .homePageContainer .description{
    font-size: 18px;
    line-height: 27px;
    margin-top: 10px;
  }
  .loginLink{
    margin-top: 10px;
    font-size: 20px;
  }
  .loginLink::before{
    width: 126px;
  }
  .logo svg{
    width: 300px;
  }

  /* @media screen and (orientation:landscape) {

  } */
}

</style>
